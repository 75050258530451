<template>
  <v-container fluid>
    <v-row class="mx-auto">
      <v-col class="float-left text-h5">
        TOTAL
        <v-btn fab text :loading="loading_global" @click="reload_global">
          <v-icon size="35">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <TransactionCard v-for="(transaction, index) in global_details.transactions" :key="index"
        :color="transaction.color" :title="transaction.title" :transactions_data="transaction.data"></TransactionCard>
    </v-row>
    <v-row class="mx-auto">
      <v-col class="float-left text-h5">
        Airtel
        <v-btn fab text :loading="loading_airtel" @click="reload_airtel">
          <v-icon size="35">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <TransactionCard v-for="(transaction, index) in airtel_details.transactions" :key="index"
        :color="transaction.color" :title="transaction.title" :transactions_data="transaction.data"></TransactionCard>
    </v-row>
    <v-row class="mx-auto">
      <v-col class="float-left text-h5">
        Vodacom
        <v-btn fab text :loading="loading_vodacom" @click="reload_vodacom">
          <v-icon size="35">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <TransactionCard v-for="(transaction, index) in vodacom_details.transactions" :key="index"
        :color="transaction.color" :title="transaction.title" :transactions_data="transaction.data"></TransactionCard>
    </v-row>
    <v-row class="mx-auto">
      <v-col class="float-left text-h5">
        Orange
        <v-btn fab text :loading="loading_orange" @click="reload_orange">
          <v-icon size="35">mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <TransactionCard v-for="(transaction, index) in orange_details.transactions" :key="index"
        :color="transaction.color" :title="transaction.title" :transactions_data="transaction.data"></TransactionCard>
    </v-row>
    <v-row class="mx-auto">
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import TransactionCard from "../components/TransactionCard.vue";
// import services from "../services/services";

export default {
  name: 'Home',
  data: () => ({
    global_details: {
      transactions: [{
        color: "blue", title: "Charge", data: {}
      }, {
        color: "blue", title: "Payout", data: {}
      }]
    },
    airtel_details: {
      transactions: [{
        color: "#DD4B39", title: "Airtel charge", data: {}
      }, {
        color: "#DD4B39", title: "Airtel payout", data: {}
      }]
    },
    vodacom_details: {
      transactions: [{
        color: "#00A65A", title: "Vodacom charge", data: {}
      }, {
        color: "#00A65A", title: "Vodacom payout", data: {}
      }]
    },
    orange_details: {
      transactions: [{
        color: "#FF851B", title: "Orange charge", data: {}
      }, {
        color: "#FF851B", title: "Orange payout", data: {}
      }]
    },
    loading_global: false,
    loading_airtel: false,
    loading_vodacom: false,
    loading_orange: false,
  }),
  components: {
    TransactionCard
  },
  methods: {
    ...mapActions(["setDailyTransactionsAction","setInstutionMerchants"]),
    detail() {
      
    },
    async reload_global() {
      this.loading_global = true
    await this.setDailyTransactionsAction()

    this.global_details.transactions[0].data = this.global_charge_details
    this.global_details.transactions[1].data = this.global_payout_details
      this.loading_global = false
    },
    async reload_airtel() {
      this.loading_airtel = true
      await this.setDailyTransactionsAction()

      this.airtel_details.transactions[0].data = this.airtel_charge_details
      this.airtel_details.transactions[1].data = this.airtel_payout_details
      this.loading_airtel = false
    },
    async reload_orange() {

      this.loading_orange = true
      await this.setDailyTransactionsAction()

      this.orange_details.transactions[0].data = this.orange_charge_details
      this.orange_details.transactions[1].data = this.orange_payout_details
      this.loading_orange = false
    },
    async reload_vodacom() {
      this.loading_vodacom = true
      await this.setDailyTransactionsAction()


      this.vodacom_details.transactions[0].data = this.vodacom_charge_details
      this.vodacom_details.transactions[1].data = this.vodacom_payout_details
      this.loading_vodacom = false
    },
  },
  computed: {
    ...mapState(["global_report_charge", "global_report_payout",
      "airtel_report_charge", "airtel_report_payout",
      "vodacom_report_charge", "vodacom_report_payout",
      "orange_report_charge", "orange_report_payout",
      "global_payout_details", "global_charge_details",
      "vodacom_payout_details", "vodacom_charge_details",
      "airtel_payout_details", "airtel_charge_details",
      "orange_payout_details", "orange_charge_details",
    ])
  },
  async mounted() {

    this.loading_global = true

    await this.setDailyTransactionsAction()

    this.global_details.transactions[0].data = this.global_charge_details
    this.global_details.transactions[1].data = this.global_payout_details

    this.loading_global = false

    this.loading_airtel = true


    this.airtel_details.transactions[0].data = this.airtel_charge_details
    this.airtel_details.transactions[1].data = this.airtel_payout_details
    this.loading_airtel = false

    this.loading_vodacom = true

    this.vodacom_details.transactions[0].data = this.vodacom_charge_details
    this.vodacom_details.transactions[1].data = this.vodacom_payout_details
    this.loading_vodacom = false

    this.loading_orange = true

    this.orange_details.transactions[0].data = this.orange_charge_details
    this.orange_details.transactions[1].data = this.orange_payout_details
    this.loading_orange = false

    
    await this.setInstutionMerchants()

  }
}

</script>


<style scoped>
</style>